.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 0 0 5%;

        .left-side {
            h2 {
                font-weight: 500;
                font-size: $font-size-60;
                color: #EAF8FF;
                padding-bottom: 45px;
                &.revetement {
                    font-size: $font-size-46;
                    padding-bottom: 15px;
                }
            }
            a.btn-link.revetement {
                width: 233px;
                &:hover {
                    width: 228px;
                }
            }
            &.revetement {
                position: relative;
                bottom: 25px;
                @media screen and (max-width: 1650px) {
                    position: initial;
                }
            }
            &.construction {
                a.btn-link.construction {
                    width: 250px;
                    &:hover {
                        width: 245px;
                    }
                }
                @media screen and (max-width: 1500px) {
                    h2 {
                        font-size: 2.15rem;
                    }
                }
            }
        }

        .right-side {
            background: $color-white;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            width: 50%;
            padding: 40px 5% 40px 30px;
        }
        @media screen and (max-width: $size-md-max) {
            position: initial;
            padding: 0 5%;
            .left-side {
                position: absolute;
                top: 30%;
                &.revetement {
                    top: 34%;
                    position: absolute;
                }
                &.realisations {
                    top: 50%;
                    padding-bottom: 0;
                }
            }
            .right-side {
                width: 100%;
                padding: 50px 0;
            }
            @media screen and (max-width: 1024px) {
                .left-side {
                    top: 28%;
                    h2 {
                        font-size: 2.5rem;
                    }
                    &.revetement {
                        top: 29%;
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .left-side {
                        top: 16%;
                        &.revetement {
                            top: 19%;
                        }
                        &.realisations {
                            top: 35%;
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .left-side {
                            top: 33%;
                            h2 {
                                font-size: 2.35rem;
                                padding-bottom: 30px;
                            }
                            &.revetement {
                                top: 32%;
                                width: 90%;
                                h2 br {
                                    display: none;
                                }
                                a.btn-link.revetement {
                                    width: 170px;
                                }
                            }
                            &.construction {
                                top: 28%;
                                a.btn-link.construction {
                                    width: 200px;
                                }
                            }
                            &.realisations {
                                top: 48%;
                                a.btn-link {
                                    width: 150px;
                                }
                            }
                        }
                        @media screen and (max-width: 375px) {
                            .left-side {
                                top: 28%;
                                &.revetement {
                                    top: 28%;
                                    h2 {
                                        font-size: 2.2rem;
                                    }
                                }
                                &.construction {
                                    top: 24%;
                                }
                                &.realisations {
                                    top: 45%;
                                }
                            }
                            @media screen and (max-width: 360px) {
                                .left-side {
                                    top: 25%;
                                    &.revetement {
                                        top: 28%;
                                        h2 {
                                            font-size: 2.1rem;
                                        }
                                    }
                                    &.construction {
                                        top: 22%;
                                        h2 {
                                            font-size: 2.1rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}