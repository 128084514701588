footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    //@extend .flex;
    @extend .flex-wrap;
    //@extend .items-center;

    .section-logo {
        padding: 20px 0 45px 0;
        margin-left: 5%;
    }

    .footer-wrapper {
        width: 100%;
        padding: 0 5%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;

        .section-copyright {
            .copyright {
                font-weight: normal;
                font-size: $font-size-14;
                color: #CACBD5;
                padding-bottom: 18px;
            }
        }
        .section-menu-footer {
            a.link {
                font-weight: normal;
                font-size: 14px;
                color: #EAF8FF;
                &:hover {
                    color: $color-1;
                }
            }
        }
        @media screen and (max-width: 1500px) {
            .section-menu-footer .bloc.mx16 {
                margin: 0 65px;
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                .section-menu-footer {
                    padding: 75px 0;
                    .bloc.mx16 {
                        margin: 0 100px;
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .section-menu-footer .bloc.mx16 {
                        margin: 0 60px;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .section-menu-footer {
                            flex-direction: column;
                            padding: 50px 0;
                            .bloc {
                                a.link.mb8 {
                                    margin-bottom: 25px;
                                }
                                &.mx16 {
                                    margin: 25px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        min-height: 550px;
        display: flex;
        align-items: center;
        .section-logo {
            padding: 0;
            margin-bottom: -75px;
        }
        @media screen and (max-width: $size-xs-max) {
            height: 630px;
            .section-logo {
                margin-bottom: -100px;
            }
        }
    }
}
