// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h2.visibilityCheck, h3.visibilityCheck, h4.visibilityCheck, h5.visibilityCheck, p.visibilityCheck {
    transform: translateY(100px);
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transform: translateY(0);
        transition: 1s;
        opacity: 1;
    }
}

img.visibilityCheck {
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transition: 1s;
        opacity: 1;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
//@import 'modules/menu';
// @import 'menu-centre';
@import 'modules/menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';


a.btn-link {
    background: $color-1;
    width: 193px;
    height: 57px;
    border-radius: 9px;
    transition: 0.3s;
    .text h5 {
        font-weight: normal;
        font-size: $font-size-14;
        color: #EAF8FF;
        letter-spacing: 2.8px;
        text-transform: uppercase;
    }
    &:hover {
        background: $color-1;
        width: 188px;
        transition: 0.3s;
    }
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-text {
    padding: 75px 5%;
    .left-side {
        width: 50%;
        margin-right: 50px;
        h4 {
            font-weight: 600;
            font-size: $font-size-26;
            color: #3D4251;
            padding-bottom: 15px;
        }
        p {
            width: 99%;
        }
    }
    .right-side {
        width: 50%;
        .bloc {
            h3 {
                font-weight: normal;
                font-size: $font-size-46;
                color: $color-1;
                text-transform: initial;
                span {
                    font-weight: 500;
                }
            }
            .border {
                background: $color-1;
                width: 138px;
                height: 2px;
                margin-top: 15px;
            }
            p {
                font-weight: bold;
            }
            &.first {
                padding-right: 145px;
            }
        }
    }
    @media screen and (max-width: 1550px) {
        .right-side {
            .bloc {
                h3 {
                    font-size: 2rem;
                }
            }
        }
        @media screen and (max-width: 1445px) {
            .left-side p {
                width: 100%;
            }
            .right-side {
                .bloc {
                    h3 {
                        font-size: 1.8rem;
                    }
                }
            }
            @media screen and (max-width: 1375px) {
                flex-direction: column-reverse;
                .right-side {
                    width: 100%;
                    margin: 0 0 50px 0;
                }
                .left-side {
                    width: 100%;
                    margin-right: 0;
                }
                @media screen and (max-width: $size-xs-max) {
                    .right-side {
                        flex-direction: column;
                        .bloc.first {
                            padding: 0 0 35px 0;
                        }
                    }
                }
            }
        }
    }
}

#section-carousel {
    .main-content {
        position: relative;
        .item {
            .col-xs-12.txt {
                padding-left: 0;
                padding-right: 0; 
                .text {
                    background: #EAF8FF;
                    padding: 30px 50px;
                    h5 {
                        font-family: $font-family-1;
                        font-weight: 500;
                        font-size: $font-size-26;
                        color: $color-2;
                        letter-spacing: 1.3px;
                        text-transform: uppercase;
                    }
                }
            }
        }   
        .owl-nav {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            width: 100%;
            padding: 0 5%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: 1650px) {
                top: 45%;
                @media screen and (max-width: $size-md-max) {
                    display: none;
                }
            }
        }   
    } 
    @media screen and (max-width: $size-sm-max) {
        .main-content {
            .item {
                .col-xs-12.txt {
                    .text h5 {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    } 
}

#section-title {
    background: $color-1;
    padding: 200px 5% 115px 5%;
    margin-top: -90px;
    text-align: center;
    h3 {
        font-weight: 600;
        font-size: $font-size-60;
        color: #EAF8FF;
    }
    @media screen and (max-width: 1550px) {
        h3 {
            font-size: 2.8rem;
        }
        @media screen and (max-width: 1395px) {
            h3 {
                br {
                    display: none;
                }
            }
            @media screen and (max-width: $size-md-max) {
                margin-top: -85px;
                h3 {
                    font-size: 2.5rem;
                }
                @media screen and (max-width: $size-xs-max) {
                    margin-top: 0;
                    padding: 100px 5%;
                    @media screen and (max-width: 375px) {
                        h3 {
                            font-size: 2.3rem;
                        }
                    }
                }
            }
        }
    }
}

#section-logo-realisations {
    padding: 90px 5% 50px 5%;
    .section-logo {
        padding-bottom: 25px;
    }
    a.link-text{
        display: flex;
        justify-content: right;
        padding-bottom: 45px;
        font-weight: normal;
        font-size: $font-size-16;
        color: $color-1;
        text-decoration: underline !important;
    }
    .section-images {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        padding-bottom: 35px;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);     
            }
        }
    }
    a.btn-link {
        width: 293px;
        &:hover {
            width: 288px;
        }
    }
    @media screen and (max-width: $size-md-max) {
        a.btn-link {
            width: 265px;
            &:hover {
                width: 260px;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            .section-logo {
                padding-bottom: 50px;
            }
            a.btn-link {
                width: 250px;
                &:hover {
                    width: 245px;
                }
            }
            @media screen and (max-width: $size-xs-max) {
                .section-logo {
                    padding-bottom: 25px;
                    flex-direction: column;
                    .logo {
                        margin: 0 0 35px 0;
                    }
                }
                a.link-text {
                    font-size: 1rem;
                    padding-bottom: 30px;
                }
                a.btn-link {
                    width: 220px;
                    &:hover {
                        width: 215px;
                    }
                }
            }
        }
    }
}

#section-logo {
    border-top: 1px solid $color-1;
    margin: 0 5%;
    padding: 90px 0;
    @media screen and (max-width: $size-xs-max) {
        flex-direction: column;
        padding: 75px 0;
        .logo {
            margin: 0 0 25px 0;
        }
    }
}

/*******************************************************************************
* PORTES ET FENÊTRES
*******************************************************************************/

#section-text-left {
    padding: 175px 0 0 0;
    position: relative;
    .text {
        position: absolute;
        left: 0;
        bottom: 0;
        background: $color-white;
        padding: 45px 45px 0 5%;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        width: 46%;
        h4 {
            font-weight: 600;
            font-size: $font-size-26;
            color: #3D4251;
            padding-bottom: 20px;
        }
        p strong {
            color: #000508;
        }
    }
    @media screen and (max-width: 1600px) {
        .text {
            width: 54%;
        }
        @media screen and (max-width: 1400px) {
            .text {
                width: 60%;
            }
            @media screen and (max-width: $size-md-max) {
                padding: 25px 0 0 0;
                @media screen and (max-width: 1150px) {
                    padding: 25px 5% 0 5%;
                    .text {
                        position: initial;
                        width: 100%;
                        padding: 50px 0 0 0;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .text h4 br {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

#section-text-right {
    margin: 100px 5%;
    position: relative;
    .text {
        position: absolute;
        right: 0;
        bottom: 0;
        background: $color-white;
        padding: 35px;
        border-top-left-radius: 30px;
        width: 50%;
        h4 {
            font-weight: 600;
            font-size: $font-size-26;
            color: #3D4251;
            padding-bottom: 20px;
        }
        p strong {
            color: #000508;
        }
        a {
            font-family: $font-family-2;
            font-size: 16px;
            color: $color-1;
            text-decoration: underline !important;
        }
    }
    @media screen and (max-width: 1737px) {
        .text {
            p br {
                display: none;
            }
        }
        @media screen and (max-width: 1500px) {
            .text {
                width: 55%;
            }
            @media screen and (max-width: 1187px) {
                .text h4 br {
                    display: none;
                }
                @media screen and (max-width: 1150px) {
                    margin: 75px 5%;
                    .text {
                        position: initial;
                        width: 100%;
                        padding: 50px 0;
                    }
                }
            }
        }
    }
}

#section-partenaires {
    padding: 0 5% 65px 5%;
    h3 {
        font-family: $font-family-2;
        font-weight: bold;
        font-size: 20px;
        color: #000508;
        text-transform: initial;
        border-bottom: 1px solid #CACBD5;
        padding-bottom: 10px;
        margin-bottom: 50px;
    }
    @media screen and (max-width: $size-xs-max) {
        .section-logo {
            flex-direction: column;
            .logo {
                margin: 0 0 30px 0;
            }
        }
    }
}

/*******************************************************************************
* REVÊTEMENT EXTÉRIEUR
*******************************************************************************/

#section-title-text {
    padding: 100px 5%;
    h2 {
        font-weight: bold;
        font-size: $font-size-60;
        color: $color-1;
        text-transform: uppercase;
        text-align: center;
    }
    p {
        text-align: center;
    }
    @media screen and (max-width: 1650px) {
        h2 {
            font-size: 2.7rem;
            br {
                display: none;
            }
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 75px 5%;
            h2 {
                font-size: 2.2rem;
            }
        }
    }
}

#section-text-left.revetement {
    padding: 0;
    @media screen and (max-width: 1318px) {
        .text p br {
            display: none;
        }
        @media screen and (max-width: 1150px) {
            padding: 25px 5% 0 5%;
            .text p br {
                display: block;
            }    
            @media screen and (max-width: $size-xs-max) {
                .text p br {
                    display: none;
                }
            }      
        }
    }
}

/*******************************************************************************
* CONSTRUCTION/RÉNOVATION
*******************************************************************************/

#section-title-text.construction h2 {
    padding-bottom: 15px;
}

#section-text-left.construction {
    margin-bottom: 75px;
    .section-list {
        padding-top: 15px;
        padding-bottom: 50px;
        .left {
            width: 55%;
        }
    }
    a.btn-link {
        width: 280px;
        .text {
            position: initial;
            background: initial;
            width: initial;
            border-radius: initial;
            padding: initial;
        }
        &:hover {
            width: 275px;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .section-list {
            flex-direction: column;
            .left {
                width: 100%;
                margin-bottom: 0.75rem;
            }
        }
        a.btn-link {
            width: 220px;
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 100px 5% 75px 5%;
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);     
            }
        }
        .img-responsive {
            width: 100%;
        }
    }
    #blocIntro_promotions {
        h3 {
            color: $color-1;
            line-height: 1.3;
        }
    }
    @media screen and (max-width: $size-xs-max){
        padding: 75px 5%;
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 0 5% 100px 5%;
    background: $color-white;
    .form-info.flex {
        position: relative;
        margin-top: -250px;
    }
    .section-form {
        width: 65%;
        margin-right: 15px;
        background: $color-white;
        box-shadow: 0px 0px 6px #00000017;
        padding: 65px 100px 85px 100px;
        h3 {
            font-family: $font-family-2;
            font-weight: bold;
            font-size: $font-size-30;
            color: #3D4251;
            padding-bottom: 15px;
        }
        .form-group {
            margin-bottom: -15px;
        }
    }
    .section-info {
        width: 35%;
        padding: 65px 45px 35px 45px;
        background: $color-2;
        h2 {
            font-family: $font-family-2;
            font-weight: bold;
            font-size: $font-size-30;
            color: #EAF8FF;
            text-transform: uppercase;
        }
        h3 {
            font-weight: 500;
            font-size: $font-size-26;
            color: #EAF8FF;
            text-transform: initial;
            padding-bottom: 5px;
        }
        .section-tel {
            padding-top: 40px;
            h4, a {
                font-weight: 500;
                font-size: 18px;
                color: #EAF8FF;
            }
        }
        .section-address {
            padding: 40px 0;
            h5 {
                font-weight: 500;
                font-size: 18px;
                color: #EAF8FF;
            }
        }
        .bloc-heures {
            p {
                color: #EAF8FF;
                padding-top: 40px;
            }
        }
        .jours-heures {
            .jour {
                width: 43%;
            }
            h4 {    
                font-weight: bold;
                font-size: 18px;
                color: #EAF8FF;
                line-height: 2;
            }
            h5 {
                font-weight: normal;
                font-size: 18px;
                color: #EAF8FF;
                line-height: 2;
            }
        }
    }
    @media screen and (max-width: 1400px) {
        .section-form {
            padding: 65px 75px 85px 75px;
        }
        @media screen and (max-width: $size-md-max) {
            .form-info {
                flex-direction: column-reverse;
                margin-top: -150px !important;
                .section-info {
                    width: 60%;
                    margin: auto;
                }
                .section-form {
                    width: 85%;
                    margin: 35px auto 0 auto;
                }
            }
            @media screen and (max-width: 1024px) {
                .form-info {
                    .section-info {
                        width: 75%;
                        padding: 65px 45px;
                        .section-tel {
                            padding-top: 70px;
                        }
                        .section-address {
                            padding: 35px 0;
                        }
                    }
                    .section-form {
                        width: 100%;
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .form-info {
                        margin-top: -95px !important;
                        .section-info {
                            width: 90%;
                            .jours-heures .jour {
                                width: 32%;
                            }
                        }
                        .section-form {
                            padding: 65px 50px 50px 50px;
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .form-info {
                            margin-top: -130px !important;
                            .section-info {
                                width: 100%;
                                padding: 50px 25px;
                                .jours-heures .jour {
                                    width: 32%;
                                }
                                h2 {
                                    text-align: center;
                                }
                            }
                            .section-form {
                                padding: 50px 25px;
                                .form-group.col-xs-12.col-sm-6.pl0 {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 0 5% 75px 5%;
    h3 {
        font-weight: 600;
        font-size: $font-size-40;
        color: $color-1;
        padding-bottom: 65px;
    }
    @media screen and (max-width: $size-sm-max) {
        #map-canvas {
            height: 500px !important;
        }
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: $color-3;
    }
}












/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   .popup-box img {
        width: 100% !important;
    }
}

@media screen and (max-width: $size-xs-max) {
   

}
