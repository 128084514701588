.btn {
    background-color: $color-1;
    border: none;
    border-radius: 9px;
    width: 155px;
    height: 75px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: normal;
    font-size: 18px;
    color: #EAF8FF;
    letter-spacing: 3.6px;
    transition: all 0.2s ease-in;

    &:hover {
        width: 150px;
        color: #EAF8FF;
        transition: all 0.2s ease-in;
    }
}
