/*****************************************************/
// MENU
/* needs main.js to toggle var menu = ".menu-mobile"; */
$size-nav-upperpart : 100px;
$size-nav-lowerpart : 30px;
$size-bar-height-etages : $size-nav-upperpart + $size-nav-lowerpart;

nav.module-menu {
    position: relative;
    padding-top: 200px;
    @media screen and (max-width: 1210px) {
        padding-top: 100px;
    }
    ul {
        list-style: none;
    }

    /****************************************************
    /* Set if the menu is over the slide show or not
    */

    //padding-bottom: $size-nav-height;// if menu NOT on top of slideshow OR
    padding-bottom: 0;// if menu ON TOP of slideshow

    //@media screen and (max-width: $size-nav-toggle-breakpoint) {
    //    padding-bottom: $size-nav-height-mobile; //no padding on mobile
    //}
    /****************************************************/

    li {
        line-height: 1.5;
    }


    /************ menu navbar ***************************/
    .menu-navbar {
        position: fixed;
        top: 0;
        z-index: $z-index-menu;
        width: 100%;
        // height: $size-nav-upperpart + $size-nav-lowerpart;
        @extend .bg-color-nav; // see _colors.scss

        &.nav-invisible {
            top: -100px;
        }
        transition: all $transition-speed-fast ease;

        .upperpart {
            height: 100px;
            background: $color-white;
            padding: 0 5%;
            // @extend .pt4;
            @extend .flex;
            @extend .justify-between;
            @extend .items-center;
            .wrapper.right {
                .main-menu-top {
                    .bloc {
                        .text {
                            h5 {
                                font-family: $font-family-1;
                                font-weight: normal;
                                font-size: $font-size-16;
                                color: #3D4251;
                                padding-bottom: 5px;
                            }
                            h4, a {
                                font-family: $font-family-1;
                                font-weight: bold;
                                font-size: $font-size-16;
                                color: #3D4251;
                            }
                        }
                    }
                    @media screen and (max-width: $size-nav-toggle-breakpoint) {
                        display: none;
                    }
                }
            }
        }
        .lowerpart {
            height: 100px;
            background-color: $color-1;
            @media screen and (max-width: $size-nav-toggle-breakpoint) {
                display: none;
            }
            .main-menu {
                @media screen and (max-width: $size-nav-toggle-breakpoint) {
                    display: none;
                }
                padding: 0 5%;
                height: 100%;
                align-items: center;
                justify-content: right;
                margin: 0;
                width: 100%;
                position: relative;
                li.item {
                  padding: 0 45px;
                  height: 100%;
                  transition: .3s;
                    a {
                        height: 100%;
                        div {
                            font-family: $font-family-2;
                            font-weight: 600;
                            color: #EAF8FF;
                            white-space: nowrap;
                            font-size: $font-size-20;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            transition: 0.3s;
                            &::before {
                                content: '';
                                background: url("../images/menu_arrow_up.png");
                                position: absolute;
                                width: 21px;
                                height: 12px;
                                bottom: 15%;
                                opacity: 0;
                                transition: 0.3s;
                            }
                            &:hover {
                                &::before {
                                    opacity: 1;
                                    transition: 0.6s;
                                }
                            }
                            @media screen and (max-width: 1570px) {
                                font-size: 0.9rem;
                            }
                        }
                    }
                    &:last-child {
                        padding: 0 0 0 30px;
                    }
                    @media screen and (max-width: 1735px) {
                        padding: 0 30px;
                        @media screen and (max-width: 1450px) {
                            padding: 0 20px;
                        }
                    }
                    &.active {
                        a div::before {
                            opacity: 1;
                            transition: 0.6s;
                        }
                    }
                }
            }
            @media screen and (max-width: $size-lg-min) {
                .main-menu {
                    li.item {
                        a div{
                            transition: .3s;
                            font-size: $font-size-18;
                        }
                    }
                }
            }
        }
    }

/************ menu toggle ***************************/
    .menu-toggle {
        display: none;
        @media screen and (max-width: $size-nav-toggle-breakpoint) {
            display: block;
        }
        cursor: pointer;
        @extend .pr3;

        .bar-top, .bar-bottom {
            // box-shadow: 0px 0px 4px $color-black;
            width: 100%;
            height: $size-nav-toggle-bar-height;
            display: block;
            @extend .bg-color-nav-toggle;
        }

        .bar-top {
             margin-bottom: 3px;
        }

        .bar-bottom {
             margin-top: 1px;
        }

        .word {
            // text-shadow: 0px 0px 6px white;
            @extend .font-1-normal;
            @extend .text-color-nav-toggle;
        }
    }

    /************ menu mobile ***************************/
    #menu-mobile {
        width: 100%;
        top: 0;
        right: -100%;
        z-index: $z-index-menu-mobile;
        // overflow: hidden;
        position: fixed;
        @extend .bg-color-nav-toggle-backdrop;
        transition: all $transition-speed-fast ease-in;
        bottom: 0; // pour avoir le scroll
        
        &.toggled {
            overflow-y: auto;
            transform: translate(-100%, 0%);
        }

        .menu-toggle {
            float: right;
            margin: 15px 0 0 0;

            .word {
                @extend .text-color-nav;
            }
        }

        ul {
            margin: 25px auto;
            padding: 30px;

            li {
                // position: relative;
                text-align: center;
                line-height: 100%;
                padding: 15px;
                border-bottom: 1px solid $color-1;

                &:nth-last-child(1) {
                    border-bottom: none;
                }

                &.top-bar {
                    display: flex;
                    @extend .flex-wrap;
                    @extend .justify-between;
                    @extend .items-center;

                    div.icons {
                        flex: 1 1;

                        & > a {
                            img {
                                margin: 5px;
                            }
                        }
                    }

                    a {
                        flex: 1 1;
                    }
                }

                & > a, & > .menu-title {
                    @extend .font-nav-items;
                    @extend .text-color-nav;
                }

                .submenu {
                    margin: 0px auto;
                    padding: 10px 0 10px;

                    ul {
                        margin: 0;
                        padding: 0;

                        li.subitem {
                            border-bottom: none;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            .menu-title {
                                margin-right: 20px;
                                font-weight: 700;

                            }
                            ul {
                                border-left: 1px $line-color-nav-mobile solid;
                                li {
                                    padding: 5px 20px;
                                }
                            }
                            a, a div {
                                @extend .font-nav-drop-items;
                                @extend .text-color-nav;
                                font-weight: 300;
                            }

                            &:hover {
                                > a, > a div {
                                    @extend .text-color-nav-hover;
                                }
                            }

                            &.active {
                                a, a div {
                                    @extend .text-color-nav-active;
                                }
                            }
                        }
                    }

                }

                &:hover {
                    > a, > a div {
                        @extend .text-color-nav-hover;
                    }
                }

                &.active, &.phone, &.lang {
                    > a, > a div {
                        color: $color-1;
                    }
                }
            }
        }
    }
}
