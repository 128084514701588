.slideshow-wrap {
    position: relative;
    .caption {
        position: absolute;
        top: 44%;
        left: 5%;
        //transform: translateX(-50%);
        z-index: $z-index-slide-caption;
        h2 {
          font-weight: 500;
          font-size: $font-size-46;
          color: $color-white;
          padding-bottom: 30px;
        }
        p {
          color: $color-white;
          line-height: 1.7;
        }
        .section-btn {
            padding-top: 80px;
            a.btn-link.left {
              width: 253px;
              &:hover {
                  width: 248px;
              }
            }
        }
        @media screen and (max-width: 1650px) {
          top: 40%;
          @media screen and (max-width: 1500px) {
            top: 35%;
            @media screen and (max-width: 1385px) {
              .section-btn {
                  padding-top: 50px;
              }
              @media screen and (max-width: 1210px) {
                top: 30%;
                @media screen and (max-width: 1115px) {
                  top: 22%;
                  @media screen and (max-width: $size-sm-max) {
                    width: 90%;
                    h2 {
                      padding-bottom: 15px;
                    }
                    .section-btn {
                      display: none;
                    }
                    p br {
                      display: none;
                    }
                    @media screen and (max-width: $size-xs-max) {
                      top: 30%;
                      width: 95%;
                      h2 br {
                        display: none;
                      }
                      @media screen and (max-width: 375px) {
                        top: 25%;
                        @media screen and (max-width: 360px) {
                          top: 27%;
                          h2 {
                            font-size: 1.9rem;
                          }
                          p {
                            font-size: 15px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }




    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 600);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                left: 0;
          			z-index: 10;
                opacity: 1;
                transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
              &.mobile {
                  display: block;
              }
              &.ecran {
                  display: none;
              }
        	}
      }
}
